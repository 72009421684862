


















































































































@import "../../assets/css/statistics";

.analysis {
	width: 65%;
}

.right {
	width: calc(100% - 67%);
	float: left;
	margin-left: 18px;

	.count {
		width: auto;
		float: none;
		margin-left: 0;
	}
}

